import React, { ChangeEvent } from 'react';

import s from './Search.scss';
import ArrowLong from 'assets/svg/arrow-long.svg';

interface IProps {
  searchString: string;
  setSearchString: any;
  setActiveCategory: any;
  setActiveLocation: any;
}

export const Search = ({
  searchString,
  setSearchString,
  setActiveCategory,
  setActiveLocation,
}: IProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    setActiveCategory('');
    setActiveLocation('');
  };

  return (
      <div className={s.search}>
        <label htmlFor="search">
          <span className={`u-visually-hidden`}>Search a position...</span>
          <input
            placeholder="Search a position..."
            type="text"
            id="search"
            onChange={onChange}
            className={s.search__input}
            value={searchString}
          />
        </label>
      <div className={s.search__arrow}><ArrowLong></ArrowLong></div>
    </div>
  );
};
