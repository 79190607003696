import React, { useEffect, useRef } from "react";
import s from "./BgLine4.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const BgLine4 = ({ pageThemeLight = false }) => {
  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "100% 100%"
      },
      {
        drawSVG: "0% 100%",
        duration: 1,
        ease: "none",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top center",
          end: "bottom bottom"
        }
      }
    );
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef}>
      <svg
        className={s("path__draw__1", { pageThemeLight })}
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={lineRef}
          xmlns="http://www.w3.org/2000/svg"
          opacity="0.2"
          d="M14 1290H166.5C276.957 1290 366.5 1200.46 366.5 1090V896V133.5C364.167 89.3333 334.4 1 234 1C133.6 1 36.1667 1 0 1"
          stroke="#1B52AD"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
