import React, { useEffect, useContext } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { UIContext } from 'context/ui';
import { isIpad } from 'utils/browserTargeting'

import { Container } from 'components/container/Container';

import s from './careers.scss';

import { Filters } from 'components/careers/filters/Filters';
import { Search } from 'components/careers/search/Search';
import { List } from 'components/careers/list/List';
import { useState } from '@reach/router/node_modules/@types/react';

interface IProps {
  careers?: any;
}

export const Careers = ({
  careers
}: IProps) => {
  let initialActiveCategory = ''
  let initialActiveLocation = ''
  let initialSearchString = ''
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    initialActiveCategory = window.careersLastActiveCategory ? window.careersLastActiveCategory : ''
    initialActiveLocation = window.careersLastActiveLocation ? window.careersLastActiveLocation : ''
    initialSearchString = window.careersLastSearchString ? window.careersLastSearchString : ''
  }
  const [locations, setLocations] = React.useState<string[]>([])
  const [activeCategory, setActiveCategory] = React.useState<string>(initialActiveCategory);
  const [activeLocation, setActiveLocation] = React.useState<string>(initialActiveLocation);
  const [searchString, setSearchString] = React.useState<string>(initialSearchString);

  const groupByCategory = (list: any, keyGetter: any) => {
    const map = new Map();
  
    list.forEach((item: any) => {
      const key = keyGetter(item);
      if(key) {
        const obj = { title: key, id: key.toLowerCase().replace(/ /g,'-')}
        const collection = map.get(key);
    
        if (!collection) map.set(key, obj);
      }
    });
  
    let sortedMap = new Map([...map.entries()].sort());

    return Array.from(sortedMap.values());
  };

  const careersCategories = groupByCategory(
    careers,
    (career: any) => {
      for (const prop of career.node.metadata){
        if (prop.name == "Career Site Category"){
          return prop.value
        }
      }
    }
  );
  
  const { isMobile, isDesktop } = useContext(UIContext);
  
  useEffect(() => {
    let newLocations = getLocationsList(careers)
    setLocations(newLocations)
  }, [])

  useEffect( () => {
    window.careersLastActiveCategory = activeCategory
    window.careersLastActiveLocation = activeLocation
    window.careersLastSearchString = searchString
    setTimeout(()=>ScrollTrigger.refresh(),200)
  }, [activeCategory, activeLocation, searchString])

  useEffect( () => {
    setTimeout(() => {
      if(isDesktop) {
        if( !isIpad() ){
          ScrollTrigger.create({
            id: 'trigger-id-filters',
            trigger: ".container-jobs",
            start: "top 15%", 
            end: "bottom 15%+=150px",
            pin: ".sticky-filters"
          });
        }
      }
    }, 1500)

    return () => {
      if(ScrollTrigger.getById('trigger-id-filters')) ScrollTrigger.getById('trigger-id-filters').kill()
    }

  }, [isDesktop] )

  const getLocationsList = (list:any[]) => {
    let locationList:string[] = []
    list.forEach((el) => {
      const location = el.node.location.name
      if (!locationList.includes(location)){
        locationList.push(location)
      }
    })
    locationList = locationList.sort((a:string, b:string)=> {
      const locationA = a.split(',')
      const locationB = b.split(',')
      if (locationA.length === 2 && locationB.length === 2){
        if (locationA[1] === locationB[1]){ // order same state cities by name too
          return (locationA[0] < locationB[0] ? -1 : 1)
        }
        return (locationA[1] < locationB[1] ? -1 : 1)
      }
      return (locationA.length >= locationB.length ? 1 : -1) // in case they forget to put a comma between city and state
    })
    return locationList
  }

  return (
    <div id="positions" className={s.jobs}>
      <a id='main' className={s.jobs__anchor}></a>
      <Container>

        <div className={s.jobs__top}>
          <div className={s.jobs__top__col}>
            <h2 className={s.jobs__title}>{careers.length} Roles in<br/>{locations.length} Locations</h2>
          </div>
          <div className={s('jobs__top__col','right')}>
            <Search
                searchString={searchString}
                setSearchString={setSearchString}
                setActiveCategory={setActiveCategory}
                setActiveLocation={setActiveLocation}
              />
          </div>
        </div>

        <div className={s.jobs__separator}>
          <div className={s.jobs__separator__col}>
          </div>
        </div>

        <div className={s('jobs__bottom', 'container-jobs')}>
          <div className={s('jobs__bottom__col', 'filters', 'sticky-filters')} >
            <Filters
              locations={locations}
              categories={careersCategories}
              activeCategory={activeCategory}
              activeLocation={activeLocation}
              setActiveCategory={setActiveCategory}
              setActiveLocation={setActiveLocation}
            />
          </div>
          <div className={s('jobs__bottom__col', 'positions')} >
            <List
              careers={careers}
              activeCategory={activeCategory}
              activeLocation={activeLocation}
              searchString={searchString}
            />
          </div>
        </div>

      </Container>
    </div>
  );
};
