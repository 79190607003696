import React, { useEffect } from 'react';
import gsap from 'gsap';

import { Link } from 'components/link/Link';
import Chevron from 'assets/svg/chevron.svg';

import s from './List.scss';

interface IProps {
  careers?: {
    node: {
      gh_Id: string;
      title: string;
    };
  };
  activeCategory: string;
  activeLocation: string;
  searchString: string;
}

interface INodeProps {
  node: {
    gh_Id: number;
    location: {
      name: string
    }
    title: string
  };
}

const groupBy = (list: any, keyGetter: any) => {
  const map = new Map();

  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) map.set(key, [item]);
    else collection.push(item);
  });

  return map;
};

export const List = ({
  careers,
  activeCategory,
  activeLocation,
  searchString,
}: IProps) => {

  let careersFiltered = careers;

  if (activeCategory) {

    careersFiltered = careers?.filter(({ node }: INodeProps) => {
      // check if activeCategory exists at the position
      let careerCategory = null
      for (const prop of node.metadata){
        if (prop.name == 'Career Site Category'){
          careerCategory = prop.value
          break
        }
      }
      if(!careerCategory) {
        return false;
      }
      let category_id = careerCategory.toLowerCase().replace(/ /g,'-');
      return category_id === activeCategory;
    });
  }

  if (activeLocation) {
    careersFiltered = careersFiltered?.filter(({ node }) => {
      return (activeLocation == node.location.name) || (activeLocation == node.location.name.split(',')[0]);
    });
  }

  if (searchString) {
    // careersFiltered?.filter(({ node }) => node.title.includes(searchString));
    careersFiltered = careersFiltered?.filter(career =>
      career.node.title.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const fallbackMsg = 'no jobs found';

  const jobsList = careersFiltered?.map(({ node }: INodeProps) => {

    return (
      <li key={node.gh_Id} className={`${s.list__job} | js-t-job-item`}>
        <Link
          to={`/jobs/${node.gh_Id}`}
        >
          <span>{node.title}</span>
          {node.location.name.split(',')[0]}
          <div className={s.list__job__label}><span>Apply</span> <Chevron /></div>
        </Link>
      </li>
    );
  });

  useEffect( () => {
    window.smoothScroll && window.smoothScroll.onResize()
    if (document.querySelector('.js-t-job-item')) {
      gsap.set('.js-t-job-item', { opacity: 0, overwrite: true })
      gsap.to('.js-t-job-item', {
        opacity: 1,
        duration: .45,
        ease: 'Power1.easeInOut',
        stagger: .05,
        overwrite: true
      });
    }
  }, [activeCategory, activeLocation, searchString ] )
  

  return (
    <div className={s.list}>
      <h3 className={s.list__title}>Available Positions</h3>
      { careersFiltered?.length > 0 ? 
        (
          <>
            <ul className={s.list__list}>
              {jobsList}
            </ul>
          </>
        ):(
          <div className={s.list__noResults}>
            {fallbackMsg}
          </div>
        )
      }
    </div>
  );
};
