import React, { useState } from 'react';
import s from './BenefitsList.scss';
import { Container } from 'components/container/Container';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import richTextOptions from 'utils/richTextOptions';


const Item = ({item, onClick, activeIndex, i}:any) => {
    return (
        <div className={s('item', {activeItem: activeIndex == i })} onClick={() => onClick(i)}>
            {String(item.title).toUpperCase()}
        </div>
    )
}

export const BenefitsList = ({list}: any) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const items = list.items.map((item:any, i:number) => {
        return (
            <Item item={item} activeIndex={activeIndex} key={i} i={i} onClick={setActiveIndex}/>
        )
    });

    return (
        <Container>
            <div className={s('container')}>
                {
                    list.title &&
                    <div className={s('title')}>
                        {list.title}
                    </div>
                }
                {
                    list.description &&
                    <div className={s('description')}>
                        {renderRichText(list.description, richTextOptions)}
                    </div>
                }
                <div className={s('content')}>
                    <div className={s('items')}>
                    {
                        items
                    }
                    </div>
                    <div className={s('details')}>
                        {
                            list.items[activeIndex].title &&
                            <div className={s('itemTitle')}>
                                {list.items[activeIndex].title}
                            </div>
                        }
                        {
                            list.items[activeIndex].text &&
                            <div className={s('itemDescription')}>
                            {renderRichText(list.items[activeIndex].text, richTextOptions)}
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        </Container>
    )
}