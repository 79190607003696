import { Container } from "components/container/Container";
import { Image } from "components/contentful/image/Image";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import richTextOptions from "utils/richTextOptions";
import s from './BadgeBanner.scss';

export const BadgeBanner = ({ list }:any) => {
    return (
        <Container>
            <div className={s('banner')}>
                {
                    list.title &&
                    <div className={s('title')}>
                        {list.title}
                    </div>
                }
                {
                    list.text &&
                    <div className={s('text')}>
                        {renderRichText(list.text, richTextOptions)}
                    </div>
                }
                <div className={s.awardsContainer}>
                    {
                        list.items.map((item: any, i: number) => (
                            <div className={s('item')}>
                                <h6 className={s('itemTitle')}>
                                    {
                                        item.title
                                    }
                                </h6>
                                <GatsbyImage alt={item.image.description} image={item.image.gatsbyImageData}
                                imgClassName={s("image")} objectFit="contain" className={s("image")}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Container>
    )
}