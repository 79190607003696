import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import {
  Prismic_Careers,
  GreenhouseJobConnection,
  GreenhouseOfficeConnection,
} from 'types/prismic';

import { Seo } from 'components/contentful/seo/Seo';
import { Hero } from 'components/contentful/hero/Hero';
import { BillboardTextContentful } from 'components/contentful/billboard-text/BillboardText';
import { Values } from 'components/values/Values';
import { MediaGridContentful } from 'components/contentful/media-grid/MediaGrid';
import { InfoListContentful } from 'components/contentful/info-list/InfoList';
import { Careers } from 'components/careers/careers';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';

import { WrapperBgs } from 'components/container/WrapperBgs';
import { BgLine1 } from 'components/bg-svgs/lines/careers-new/bg-line-1/BgLine1';
import { BgLine2 } from 'components/bg-svgs/lines/careers-new/bg-line-2/BgLine2'
import { BgLine3 } from 'components/bg-svgs/lines/careers-new/bg-line-3/BgLine3';
import { BgLine4 } from 'components/bg-svgs/lines/careers-new/bg-line-4/BgLine4';
import { BgLine5 } from 'components/bg-svgs/lines/careers/bg-line-5/BgLine5';

import { BgLineMobile1 } from 'components/bg-svgs/lines/careers/bg-line-mobile-1/BgLineMobile1';
import { BgLineMobile3 } from 'components/bg-svgs/lines/careers/bg-line-mobile-3/BgLineMobile3';
import { BgLineMobile4 } from 'components/bg-svgs/lines/careers/bg-line-mobile-4/BgLineMobile4';
import { Mesh } from 'components/bg-svgs/mesh/mesh/Mesh';
// import { Mesh } from 'components/bg-svgs/mesh/mesh/Mesh';

import { UIContext } from 'context/ui';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';
import { BenefitsList } from 'components/benefits-list/BenefitsList';
import { QuoteCarousel } from 'components/quote-carousel/QuoteCarousel';
import { BadgeBanner } from 'components/badge-banner/BadgeBanner';
gsap.registerPlugin(ScrollTrigger);

interface IProps {
  data?: {
    prismic?: {
      careers?: Prismic_Careers;
    };
    allGreenhouseJob: any;
  };
  allGreenhouseJob: GreenhouseJobConnection;
  allGreenhouseOffice: GreenhouseOfficeConnection;
  location?: Location;
}

export default ({ data, location }: any) => {
  if (!data) return;

  const { isMobile, isDesktop } = useContext(UIContext);
  
  const { edges: careers } = data?.allGreenhouseJob;
  const contentfulData = data.contentfulData;

  return (
    <>
      <Seo
        seo={contentfulData.seo}
        url={location!.pathname}
      />

      <BgGradient />

      <WrapperBgs overflowMobile={true}>
        {isDesktop && <BgLine1 />}
        <Hero
          hero={{...contentfulData.hero, clearPaddingBottom: true}}
        />
        <MediaTextContentful
          layout="column"
          extraSpacingBottom={false}
          inverted={true}
          topRadius={true}
          noAspectRatio={true}
          mediaText={contentfulData.body[0]}
          paddingTop={0}
          style={{paddingBottom: isMobile && 0}}
          />
      </WrapperBgs>

      <WrapperBgs overflowMobile={true}>
        
        <BillboardTextContentful
        lessSpacing billboardText={contentfulData.body[1]}
        style={{padding: isDesktop ? '80px 0' : '100px 0'}}
        />
        {isDesktop && <BgLine2 style={{display: 'flex', alignItems: 'center'}}/>}
      </WrapperBgs>

      <WrapperBgs extraClassName='below-the-hero'>
        <InfoListContentful
          style={{marginBottom: isDesktop && 160,
                  paddingBottom: isDesktop && 0,
                }}
          infoList={contentfulData.body[2]}
          titlePlacement="inside"
          borderRadius="bottom"
          titleAlignTop={true}
        />
        {isDesktop && <BgLine3/>}
      </WrapperBgs>

      <WrapperBgs>
        <BenefitsList list={contentfulData.body[3]}/>
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful 
          infoList={contentfulData.body[4]}
          titlePlacement="inside"
          titleAlignTop={true}
          imageAlignment="left"
          borderRadius='rop-right'
          style={{
            marginTop: isMobile && 100
          }}
        />
        <QuoteCarousel list={contentfulData.body[5]}/>
        { isDesktop && <BgLine4 />}
      </WrapperBgs>

      <WrapperBgs>
        <BadgeBanner list={contentfulData.body[6]}/>
      </WrapperBgs>

      <WrapperBgs>
        <Mesh/>
        <Careers careers={careers}/>
      </WrapperBgs>
      
    </>
  );
};

export const query = graphql`
  {
    allGreenhouseJob(filter: {departments: {elemMatch: {name: {ne: "Templates"}}}}, sort: { fields: departments___name, order: ASC }) {
      edges {
        node {
          gh_Id
          title
          metadata {
            name
            value
          }
          location {
            name
          }

          departments {
            gh_Id
            name
          }
          offices {
            gh_Id
            name
          }
        }
      }
    }

    contentfulData: contentfulPage(name: {eq: "Careers"}) {
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      name
      body {
        ... on ContentfulBillboard {
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulInfoList {
          id
          title
          description {
            raw
          }
          items {
            image {
              gatsbyImageData
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            title
            text {
              raw
            }
            video {
              file {
                url
              }
              description
            }
          }
          subtitle
        }
        ... on ContentfulList {
          id
          title
          text {
            raw
          }
          cta {
            label
            href
          }
          items {
            title
            text {
              raw
            }
            quote {
              author
              text {
                raw
              }
              avatar {
                description
                gatsbyImageData
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
            }
            image {
              gatsbyImageData
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            video {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            description
          }
        }
        ... on ContentfulQuote {
          id
          author
          text {
            raw
          }
        }
        ... on ContentfulMediaText {
          id
          videoUrl
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulVideo {
          id
          videoUrl
          video {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        title
        text {
          raw
        }
        cta {
          label
          href
        }
      }
    }
  }
`;
