import React, { useContext, useEffect, useState } from 'react';

import { UIContext } from 'context/ui';
import { isIpad } from 'utils/browserTargeting'
import Chevron from 'assets/svg/chevron.svg';

import s from './Filters.scss';

interface IProps {
  categories?: any;
  locations: string[],
  activeCategory: string;
  activeLocation: string;
  setActiveCategory: any;
  setActiveLocation: any;
}

interface INodeProps {
  node: {
    gh_Id: number;
    name: string;
    parent_id: number;
  };
  index: number;
}

export const Filters = ({
  locations,
  categories,
  activeCategory,
  activeLocation,
  setActiveCategory,
  setActiveLocation,
}: IProps) => {

  const { isMobile, isDesktop } = useContext(UIContext);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const locationOptions = locations?.map((location: string) => (
    <option key={location} value={location}>
      {location}
    </option>
  ));


  const categoriesOptions = categories.map((node: any) => {
      
    return (
      <option key={node.id} value={node.id}>
        {node.title}
      </option>
    );

  });

 

  const defaultValue = '';

  /* useEffect( () => {
    if(isDesktop && !isIpad() && !isFirstRender && window.smoothScroll) {
      window.smoothScroll.disable()
      const yDestination = document.querySelector('#main').offsetTop
      window.smoothScroll.scrollTo(yDestination)
      setTimeout( () => window.smoothScroll.enable(), 50 )
    }
    setIsFirstRender(false)
  }, [activeCategory, activeLocation] ) */

  return (
    <div className={s.filters}>

        <div className={s.filters__row}>

          <div className={s.filters__col}>

              <div className={s.filters__select} >
                <label htmlFor="locations" className={s.filters__select}>
                  <span className={`u-visually-hidden`}>Locations</span>
                  <select
                    id="locations"
                    name="locations"
                    onChange={e => setActiveLocation(e.target.value)}
                    value={activeLocation}
                    >
                    <option value={defaultValue}>
                      { activeLocation === '' ? 'Locations' : 'All Locations' }
                    </option>
                    {locationOptions}
                  </select>
                  <Chevron />
                </label>
              </div>

          </div>

          <div className={s('filters__col','right')}>

              <div className={s.filters__select} >
                <label htmlFor="category" className={s.filters__select}>
                  <span className={`u-visually-hidden`}>Function</span>
                  <select
                    id="category"
                    name="category"
                    onChange={e => setActiveCategory(e.target.value)}
                    value={activeCategory}
                    >
                    <option value={defaultValue}>{ activeCategory === '' ? 'Functions' : 'All Functions' }</option>
                    {categoriesOptions}
                  </select>
                  <Chevron />
                </label>
              </div>

          </div>

        </div>

    </div>
  );
};
